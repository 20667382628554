require('./bootstrap');

import Swal from 'sweetalert2';
import Chart from 'chart.js/auto';

require('bootstrap-slider/dist/bootstrap-slider')

window.Swal = require('sweetalert2').default;
window.Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
});

window.Chart = Chart;
